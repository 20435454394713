/**
 * Custom Footer Component - CH
 */
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
footer {
  border: 1px solid #eaebeb;
  margin-bottom: 20px;
}

footer .component.plain-html {
  width: 100%;
}

footer .social-links {
  padding: 16px 0 17.7px;
}

footer .social-links .row {
  display: flex;
  width: 100%;
  margin: 0;
  justify-content: center;
}

footer .social-links .row .col {
  flex-grow: 0;
  padding: 0;
  flex-basis: 40px;
}

footer .social-links .row .col a {
  padding: 0 9px;
  display: inline-block;
}

footer .social-links .row .col a:hover {
  opacity: 0.8;
}

footer .social-links .row .col a i {
  display: inline-block;
  width: 33px;
  height: 33px;
  padding: 0;
}

footer .social-links .row .col a i.icon-facebook {
  background-image: url("../images/icons/ico-round-fb.svg");
}

footer .social-links .row .col a i.icon-instagram {
  background-image: url("../images/icons/ico-round-insta.svg");
}

footer .social-links .row .col a i.icon-linkedin {
  background-image: url("../images/icons/ico-round-linkedin.svg");
}

footer .social-links .row .col a i.icon-youtube {
  background-image: url("../images/icons/ico-round-youtube.svg");
}

footer > .container {
  max-width: none;
  padding: 7rem 4.8rem 3.5rem;
  background-color: #f7f7f7;
  background-image: url("../images/footer-bg.png");
  background-repeat: no-repeat;
  background-position: right top;
  background-size: cover;
}

@media (min-width: 1630px) {
  footer > .container .col-lg-4,
  footer > .container .col-lg-8 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

footer > .container > .row {
  margin: 0;
}

@media (max-width: 991px) {
  footer {
    padding: 15px;
    text-align: center;
  }
}

footer .footer-copyright-wrapper {
  display: flex;
  align-items: baseline;
}

footer .footer-copyright-wrapper > .component {
  display: inline-block;
  flex: auto;
}

footer .footer-copyright-wrapper p {
  margin: 0;
  font-size: 1em;
  line-height: 1.25em;
}

@media (min-width: 576px) {
  footer .logo img {
    margin: 0 0 0 25px;
  }
}
